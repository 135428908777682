import React, { useState } from 'react';
import { Container, Col } from 'react-bootstrap';
import { OtherNews } from '../../queries/common_use_query';
import CommonSlider from '../CommonSlider/CommonSlider';
import GetGGFXImage from '../common/site/get-ggfx-image';
import '../NewsListing/NewsListing.scss'
import './OtherNews.scss'
import { NewsBaseURL } from '../common/site/utils';
import { Link } from 'gatsby';
import moment from 'moment';
import PlayVideo from '../PlayVideo/PlayVideo';
const OtherNewsSlider = (props) => {
  const {loading: loading,error: error,data: news_data} = OtherNews(props.id)    
  const [isPlay, setPlay] = useState(false);
  const [videourl, setVideourl] = useState(false);
  return (
    <section className='section-p grey-bg other-news-wrapper news-listing'>
      <Container>
            <div className='modulecards news-listing-wrapper'>
              <h3>Other Stories</h3>
            {news_data && news_data.newsInsights?.length > 0 ?
                <CommonSlider>
                {news_data.newsInsights.map((news) => {
                    let processedImages = JSON.stringify({});
                    if (news?.imagetransforms?.Tile_Image_Transforms) {
                        processedImages = news.imagetransforms.Tile_Image_Transforms;
                    }
                    const href = !news.Video_URL ? {to:NewsBaseURL+news.URL+'/ '} : {href: "javascript:void(0)"}
                    return<>
                        <div className={`${news.Video_URL ? 'video-item-wrap' : '' } news-item-wrap`}>
                            <div className='img-zoom rounded'>
                              <Link {...href}>
                                <GetGGFXImage imagename={"news-insights.Tile_Image.listimg"} imagesource={news.Tile_Image} fallbackalt={news.Title} imagetransformresult={processedImages} id={news.id}/>    
                              </Link>
                              {news.Video_URL && <a className='btn-play' href="javascript:void(0)" onClick={(e) => {setPlay(true); setVideourl(news.Video_URL)}}><i className="icon icon-play"></i></a>}
                            </div>
                            <div className='news-cat'>{news.select_categories?.Name}</div>
                            <div className='news-title'><Link {...href}>{news.Title}</Link></div>
                            <div className='news-date'>{moment(news.News_Date).format("Do MMMM YYYY")}</div>
                        </div>
                        {isPlay && videourl &&
                          <PlayVideo isOpen={isPlay} stopPlay={setPlay} videoId="" isCloseFunction={setPlay} videourl={videourl} htmlink={""} />
                        }
                    </>
                }
                )}
                </CommonSlider>
                :""}
            </div>
      </Container>
    </section>
  );
}

export default OtherNewsSlider;
