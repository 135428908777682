import React from 'react'
import GetGGFXImage from '../common/site/get-ggfx-image'
import { Container, Row, Col } from 'react-bootstrap';
import './NewsDetails.scss'
import moment from "moment";
import parse from "html-react-parser"
import { Helmet } from "react-helmet";
import SidebarNewsletter from '../SidebarNewsletter/SidebarNewsletter';
import OtherNews from '../OtherNews/OtherNews';
import Share from '../share/share';
import SiteBreadcrumbs from '../Breadcrumb/site-breadcrumbs';
import { Link } from 'gatsby';
import { ValuationURL } from '../common/site/utils';
const NewsDetails = ({ news }) => {
    let processedImages = JSON.stringify({});
    if (news?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = news.imagetransforms.Banner_Image_Transforms;
    }
    return (
      <>
        <Helmet><script async src="//www.instagram.com/embed.js"></script></Helmet>
        <section className='news-details'>
            <Container fluid>
                <div className='detail-banner rounded'>
                    <SiteBreadcrumbs alias="latest-property-news" menulabel={news.Title}/>
                    <div className='rounded'>
                    <GetGGFXImage
                        imagename={"news-insights.Banner_Image.bannerimg"}
                        imagesource={news.Banner_Image}
                        fallbackalt={news.Title}
                        imagetransformresult={processedImages}
                        id={news.id}
                    />
                    </div>
                    <Container className='news-content-container'>
                    <div className='banner-overlay d-none d-lg-block'></div>
                    <div className='news-banner-content'>
                        <Col lg={`8`}>
                            <h1>{news.Title}</h1>
                            <div className='news-actions'>
                                <div className='news-date'>{moment(news.News_Date).format("Do MMMM YYYY")}</div>
                                <span> / </span>
                                <div className='news-share'>
                                    <Share text="Share this Post" />
                                </div>
                            </div>
                        </Col>
                    </div>
                    </Container>
                </div>
            </Container>
            <Container className='content-wrapper'>
                <Row>
                    <Col lg={`8`}>
                        {news.Above_Content ? parse(news.Above_Content) : ""}
                        <div className='get-in-touch rounded'>
                            <div className='left-gt'>
                                <h3>Looking to Sell or Let your property?</h3>
                                <p>Our team of local experts are here to help you.</p>
                            </div>
                            <div className='right-gt'>
                                <Link to={ValuationURL} className='btn btn-fill-primary'>Book a valuation</Link>
                            </div>
                        </div>
                        {news.Below_Content ? parse(news.Below_Content) : ""}
                        <div className='divider'></div>
                    </Col>
                    <Col lg={`1`} className="divider-col d-none d-lg-block"><span></span></Col>
                    <Col lg={`3`}>
                        <SidebarNewsletter />
                    </Col>
                </Row>
            </Container>
            <OtherNews id={news.id}/>
        </section>
      </>
    )
}

export default NewsDetails