import React from 'react'
import { graphql } from "gatsby"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import NewsDetails from '../components/NewsDetails/NewsDetails'
import SEO from '../components/Seo/seo'

const NewsDetailTemplate = ({ data }) => {
  const news = data.glstrapi.newsInsight
  return (
    <>
      <SEO
        title={news.Meta_Title ? news.Meta_Title : news.Title}
        description={news.Meta_Description ? news.Meta_Description : `Read about ${news.Title} here and subscribe to our newsletter to stay up-to-date about everything going on at David Astburys.`}
      />
        <Header/>
            <NewsDetails news={news}/>
        <Footer/>
    </>
  )
}

export default NewsDetailTemplate

export const pageQuery = graphql`
  query GetNewsPage($articleId: ID!) {
    glstrapi {
        newsInsight(id: $articleId, publicationState: LIVE) {
            id
            Title
            URL
            News_Date
            Meta_Title
            Meta_Description            
            Banner_Image {
                url
                alternativeText
            }
            Above_Content
            Below_Content
            imagetransforms
        }
    }
  }
`

